/* global ymaps*/
import React, { Suspense } from "react";
import {
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";
import logo from "../../images/logo.png";
import logowebp from "../../images/logo.webp";
import logoavif from "../../images/logo.avif";
import Header from "../Header/Header";
import { VideoContext, videos } from "../../context/VideoContext";
import { ComandaContext, comanda } from "../../context/ComandaContext";
import { GalleryContext, gallery } from "../../context/GalleryContext";
import { PriceContext, price } from "../../context/PriceContext";
import { DiplomsContext, diploms } from "../../context/DiplomsContext";
import Thanks from "../Thanks/Thanks";
import Footer from "../Footer/Footer";
import { RewievsContext, rewievs } from "../../context/RewievsContext";
import Oferta from "../Oferta/Oferta";

const Main = React.lazy(() => import("../Main/Main"));
const Video = React.lazy(() => import("../Video/Video"));
const Form = React.lazy(() => import("../Form/Form"));
const Stages = React.lazy(() => import("../Stage/Stage"));
const Comanda = React.lazy(() => import("../Comanda/Comanda"));
const Gallery = React.lazy(() => import("../Gallery/Gallery"));
const Price = React.lazy(() => import("../Price/Price"));
const Rewiev = React.lazy(() => import("../Rewiev/Rewiev"));
const Diploms = React.lazy(() => import("../Diploms/Diploms"));
const Contacts = React.lazy(() => import("../Contacts/Contacts"));
const Popup = React.lazy(() => import("../Popup/Popup"));
const Policy = React.lazy(() => import("../Policy/Policy"));
const Error = React.lazy(() => import("../Error/Error"));

function App() {
  const [isThanks, setThanks] = React.useState(false);
  const [isOpenPopupForm, setOpenPopupForm] = React.useState(false);
  const [isOpenPopupImg, setOpenPopupImg] = React.useState(false);
  const [isOpenPopupPolicy, setOpenPopupPolicy] = React.useState(false);
  const [isOpenPopupInfo, setOpenPopupInfo] = React.useState(false);
  const [isOpenPopupOferta, setOpenPopupOferta] = React.useState(false);
  const [infoImg, setInfoImg] = React.useState(false);
  const [infoTitle, setInfoTitle] = React.useState(false);
  const [infoSubtitle, setInfoSubtitle] = React.useState(false);
  const [infoText, setInfoText] = React.useState(false);
  const [isBig, setBig] = React.useState(false);
  const [isGallery, setGallery] = React.useState(false);
  const [isDiploms, setDiploms] = React.useState(false);
  const [isPopupImg, setPopupImg] = React.useState();
  const [slideImg, setSlideImg] = React.useState(0);
  const [keyGallery, setKeyGallery] = React.useState(1);
  const [slideGallery, setSlideGallery] = React.useState([1, 2, 3, 4]);
  const [keyDiploms, setKeyDiploms] = React.useState(1);
  const [slideDiploms, setSlideDiploms] = React.useState([1, 2, 3, 4]);
  const [isActiveMap, setActiveMap] = React.useState(true);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  const history = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  function slidePlus(data, setSlide, slide) {
    if (slide[0] >= data.length) {
      setSlide([1, 2, 3, 4]);
    } else {
      if (slide[1] >= data.length) {
        setSlide([data.length, 1, 2, 3]);
      } else {
        if (slide[2] >= data.length) {
          setSlide([data.length - 1, data.length, 1, 2]);
        } else {
          if (slide[3] >= data.length) {
            setSlide([data.length - 2, data.length - 1, data.length, 1]);
          } else {
            setSlide([slide[0] + 1, slide[1] + 1, slide[2] + 1, slide[3] + 1]);
          }
        }
      }
    }
  }
  function slideMinus(data, setSlide, slide) {
    if (slide[0] <= 1) {
      setSlide([data.length, 1, 2, 3]);
    } else {
      if (slide[1] <= 1) {
        setSlide([data.length - 1, data.length, 1, 2]);
      } else {
        if (slide[2] <= 1) {
          setSlide([data.length - 2, data.length - 1, data.length, 1]);
        } else {
          if (slide[3] <= 1) {
            setSlide([
              data.length - 3,
              data.length - 2,
              data.length - 1,
              data.length,
            ]);
          } else {
            setSlide([slide[0] - 1, slide[1] - 1, slide[2] - 1, slide[3] - 1]);
          }
        }
      }
    }
  }
  function onClose() {
    setOpenPopupForm(false);
    setOpenPopupImg(false);
    setOpenPopupPolicy(false);
    setOpenPopupInfo(false);
    setOpenPopupOferta(false);
    setDiploms(false);
    setGallery(false);
    setBig(false);
    setPopupImg("");
  }
  // Hook
  const useOnScreen = (options) => {
    const ref = React.useRef();
    const [visible, setVisible] = React.useState(false);
    React.useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setVisible(true);
        }
      }, options);
      if (ref.current) {
        observer.observe(ref.current);
      }
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [ref, options]);
    return [ref, visible];
  };
  window.onload = () => {
    function init(ymaps) {
      var myMapMsk = new ymaps.Map(
        "map_msk",
        {
          center: [55.755821, 37.617635],
          zoom: 10,
          type: "yandex#map",
        },
        {
          suppressMapOpenBlock: true,
        }
      );
      var myPlacemark4 = new ymaps.Placemark([55.80487, 37.57996], {}, {});
      var myPlacemark5 = new ymaps.Placemark([55.717792, 37.825658], {}, {});
      var myPlacemark6 = new ymaps.Placemark([55.722735, 37.692572], {}, {});
      var myPlacemark7 = new ymaps.Placemark([55.768319, 37.830581], {}, {});
      var myPlacemark8 = new ymaps.Placemark([55.837267, 37.537964], {}, {});
      function disabledMap() {
        myMapMsk.behaviors.disable("scrollZoom");
        myMapMsk.behaviors.disable("drag");
        setActiveMap(true);
      }
      function enabledMap() {
        myMapMsk.behaviors.enable("scrollZoom");
        myMapMsk.behaviors.enable("drag");
        setActiveMap(false);
      }
      disabledMap();
      myMapMsk.geoObjects
        .add(myPlacemark4)
        .add(myPlacemark5)
        .add(myPlacemark6)
        .add(myPlacemark7)
        .add(myPlacemark8);
      window.addEventListener("keydown", (e) => {
        e.key === "Control" ? enabledMap() : disabledMap();
      });
      window.addEventListener("keyup", (e) => {
        disabledMap();
      });
      window.addEventListener("touchstart", (e) => {
        e.touches.length > 1 ? enabledMap() : disabledMap();
      });
    }
    setTimeout(function () {
      ymaps.ready(init);
    }, 1000);
  };
  return (
    <div className="app">
      <Header
        logo={logo}
        logowebp={logowebp}
        logoavif={logoavif}
        setOpenPopupForm={setOpenPopupForm}
      />
      <Suspense fallback={<div></div>}>
        {location.pathname === "/" || location.pathname === "thank-you" ? (
          <>
            <Main setOpenPopupForm={setOpenPopupForm} />
            <VideoContext.Provider value={videos}>
              <Video useOnScreen={useOnScreen} />
            </VideoContext.Provider>
            <Form
              history={history}
              setThanks={setThanks}
              onClose={onClose}
              setOpenPopupPolicy={setOpenPopupPolicy}
              class="form__container__main"
              button="Записаться на занятие"
            >
              <h2 className="form__title">Количество мест ограничено!</h2>
              <p className="form__subtitle">
                Оставьте заявку и мы Вам перезвоним в течении 5 минут
              </p>
            </Form>
            <Stages setOpenPopupForm={setOpenPopupForm} />
            <ComandaContext.Provider value={comanda}>
              <Comanda
                useOnScreen={useOnScreen}
                setInfoImg={setInfoImg}
                setInfoSubtitle={setInfoSubtitle}
                setInfoTitle={setInfoTitle}
                setInfoText={setInfoText}
                setOpenPopupInfo={setOpenPopupInfo}
              />
            </ComandaContext.Provider>
            <GalleryContext.Provider value={gallery}>
              <Gallery
                useOnScreen={useOnScreen}
                setGallery={setGallery}
                setOpenPopupImg={setOpenPopupImg}
                setPopupImg={setPopupImg}
                slideMinus={slideMinus}
                slidePlus={slidePlus}
                slide={slideGallery}
                setSlide={setSlideGallery}
                keyGallery={keyGallery}
                setKey={setKeyGallery}
              />
            </GalleryContext.Provider>
            <Form
              history={history}
              setThanks={setThanks}
              onClose={onClose}
              setOpenPopupPolicy={setOpenPopupPolicy}
              class="form__container__main"
              button="Записаться на занятие"
            >
              <h2 className="form__title">
                Подарите своему ребенку билет в активное и творческое будущее
              </h2>
            </Form>
            <PriceContext.Provider value={price}>
              <Price
                useOnScreen={useOnScreen}
                setOpenPopupInfo={setOpenPopupInfo}
                setInfoImg={setInfoImg}
                setInfoSubtitle={setInfoSubtitle}
                setInfoTitle={setInfoTitle}
                setInfoText={setInfoText}
              />
            </PriceContext.Provider>
            <RewievsContext.Provider value={rewievs}>
              <Rewiev
                dimensions={dimensions}
                slideMinus={slideMinus}
                slidePlus={slidePlus}
              />
            </RewievsContext.Provider>
            <DiplomsContext.Provider value={diploms}>
              <Diploms
                useOnScreen={useOnScreen}
                dimensions={dimensions}
                setDiploms={setDiploms}
                setSlideImg={setSlideImg}
                setOpenPopupImg={setOpenPopupImg}
                setPopupImg={setPopupImg}
                slideMinus={slideMinus}
                slidePlus={slidePlus}
                slide={slideDiploms}
                setSlide={setSlideDiploms}
                keyDiploms={keyDiploms}
                setKey={setKeyDiploms}
              />
            </DiplomsContext.Provider>
            <Contacts dimensions={dimensions} isActiveMap={isActiveMap} />
          </>
        ) : null}
        <Routes>
          <Route path="*" element={<Error />} />
          <Route path="/" element={<></>} />
          <Route
            path="/thank-you"
            element={isThanks ? <Thanks /> : <Navigate to="/" />}
          />
        </Routes>
        <Footer
          logo={logo}
          logowebp={logowebp}
          logoavif={logoavif}
          setOpenPopupForm={setOpenPopupForm}
          setOpenPopupPolicy={setOpenPopupPolicy}
          setOpenPopupOferta={setOpenPopupOferta}
        />
        <Popup onClose={onClose} isOpen={isOpenPopupForm} name="form">
          <Form
            history={history}
            setThanks={setThanks}
            onClose={onClose}
            setOpenPopupPolicy={setOpenPopupPolicy}
            titleSubmit="Заявка на звонок"
            class={"form__container"}
            button="записаться на занятие"
          >
            <h2 className="form__title_popup">
              Оставьте заявку и мы перезвоним{" "}
              <span className="form__title-span">через 5 минут</span>
            </h2>
          </Form>
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupImg} name="image">
          <>
            {isDiploms ? (
              <button
                className="popup__arrow popup__arrow_left"
                onClick={() => {
                  slideMinus(diploms, setSlideDiploms, slideDiploms);
                  keyDiploms <= 1
                    ? setKeyDiploms(diploms.length)
                    : setKeyDiploms(keyDiploms - 1);
                  slideImg <= 0
                    ? setSlideImg(diploms.length - 1)
                    : setSlideImg(slideImg - 1);
                  // key <= 1
                  //   ? setMainFoto(isCaseSrc[21].big)
                  //   : setMainFoto(isCaseSrc[slide[0] - 2].big);
                  slideImg <= 0
                    ? setPopupImg({
                        webp: diploms[diploms.length - 1].bigwebp,
                        avif: diploms[diploms.length - 1].bigavif,
                        ordinary: diploms[diploms.length - 1].big,
                      })
                    : setPopupImg({
                        webp: diploms[slideImg - 1].bigwebp,
                        avif: diploms[slideImg - 1].bigavif,
                        ordinary: diploms[slideImg - 1].big,
                      });
                }}
              ></button>
            ) : null}

            {isGallery ? (
              <button
                className="popup__arrow popup__arrow_left"
                onClick={() => {
                  slideMinus(gallery, setSlideGallery, slideGallery);
                  keyGallery <= 1
                    ? setKeyGallery(gallery.length)
                    : setKeyGallery(keyGallery - 1);
                  slideImg <= 0
                    ? setSlideImg(gallery.length - 1)
                    : setSlideImg(slideImg - 1);
                  // key <= 1
                  //   ? setMainFoto(isCaseSrc[21].big)
                  //   : setMainFoto(isCaseSrc[slide[0] - 2].big);
                  slideImg <= 0
                    ? setPopupImg({
                        webp: gallery[gallery.length - 1].bigwebp,
                        avif: gallery[gallery.length - 1].bigavif,
                        ordinary: gallery[gallery.length - 1].big,
                      })
                    : setPopupImg({
                        webp: gallery[slideImg - 1].bigwebp,
                        avif: gallery[slideImg - 1].bigavif,
                        ordinary: gallery[slideImg - 1].big,
                      });
                }}
              ></button>
            ) : null}
            {isOpenPopupImg ? (
              <picture>
                <source srcSet={isPopupImg.webp} type="image/webp" />
                <source srcSet={isPopupImg.avif} type="image/avif" />
                <source srcSet={isPopupImg.ordinary} type="image/jpeg" />
                <img
                  className={isBig ? "popup__img popup__img_big" : "popup__img"}
                  onClick={() => setBig(!isBig)}
                  src={isPopupImg.ordinary}
                  alt="Фото"
                  type="image/jpeg"
                />
              </picture>
            ) : null}
            {isDiploms ? (
              <button
                className="popup__arrow popup__arrow_right"
                onClick={() => {
                  slidePlus(diploms, setSlideDiploms, slideDiploms);
                  keyDiploms >= diploms.length
                    ? setKeyDiploms(1)
                    : setKeyDiploms(keyDiploms + 1);
                  slideImg >= diploms.length - 1
                    ? setSlideImg(0)
                    : setSlideImg(slideImg + 1);
                  //  key >= diploms.length
                  //     ? setMainFoto(diploms[1].big)
                  //     : setMainFoto(diploms[key+1].big);
                  slideImg >= diploms.length - 1
                    ? setPopupImg({
                        webp: diploms[0].bigwebp,
                        avif: diploms[0].bigavif,
                        ordinary: diploms[0].big,
                      })
                    : setPopupImg({
                        webp: diploms[slideImg + 1].bigwebp,
                        avif: diploms[slideImg + 1].bigavif,
                        ordinary: diploms[slideImg + 1].big,
                      });
                }}
              ></button>
            ) : null}
            {isGallery ? (
              <button
                className="popup__arrow popup__arrow_right"
                onClick={() => {
                  slidePlus(gallery, setSlideGallery, slideGallery);
                  keyGallery >= gallery.length
                    ? setKeyGallery(1)
                    : setKeyGallery(keyGallery + 1);
                  slideImg >= gallery.length - 1
                    ? setSlideImg(0)
                    : setSlideImg(slideImg + 1);
                  //  key >= gallery.length
                  //     ? setMainFoto(gallery[1].big)
                  //     : setMainFoto(gallery[keyGallery+1].big);
                  slideImg >= gallery.length - 1
                    ? setPopupImg({
                        webp: gallery[0].bigwebp,
                        avif: gallery[0].bigavif,
                        ordinary: gallery[0].big,
                      })
                    : setPopupImg({
                        webp: gallery[slideImg + 1].bigwebp,
                        avif: gallery[slideImg + 1].bigavif,
                        ordinary: gallery[slideImg + 1].big,
                      });
                }}
              ></button>
            ) : null}
          </>
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupInfo} name="info">
          {isOpenPopupInfo ? (
            <>
              <div className="popup__info">
                <picture>
                  <source srcSet={infoImg.webp} type="image/webp" />
                  <source srcSet={infoImg.avif} type="image/avif" />
                  <source srcSet={infoImg.src} type="image/png" />
                  <img
                    className="info__img"
                    src={infoImg.src}
                    alt="logo"
                    type="image/png"
                  />
                </picture>
                <h2 className="info__title">{infoTitle}</h2>
                <p className="info__subtitle">{infoSubtitle}</p>
                {infoText.map((t) => (
                  <p className="info__text">{t}</p>
                ))}
                <button
                  className="info__button"
                  onClick={() => setOpenPopupForm(true)}
                  type="button"
                >
                  Записаться на занятие
                </button>
              </div>
            </>
          ) : null}
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupPolicy} name="policy">
          <Policy />
        </Popup>
        <Popup onClose={onClose} isOpen={isOpenPopupOferta} name="oferta">
          <Oferta />
        </Popup>
      </Suspense>
    </div>
  );
}

export default App;
